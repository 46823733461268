import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import Gist from 'react-gist';
import '../assets/css/style.css';

const IndexPage = () => (
  <Layout title="Terms and Conditions">
    <SEO title="Email Design Service For Amazon Web Service SES" />
   
    <div className="max-width-4 mx-auto terms">
    
    <h2>Terms of Service</h2>
<p className="mt2">Emailkick is an analytics dashboard, reporting and business intelligence tool for transactional emails sent through Mailgun or Amazon SES. The way it works is it connects to your Mailgun/SES account via webhooks and APIs to receive your mail events. These events are then mined, formatted and presented in an easy to understand form.
<br /><br />
By visiting the website and using Emailkick, you agree to be bound by this terms. You agree to indemnify and hold Emailkick harmless from and against any and all claims and expenses, including attorneys' fees, arising out of your use of the service.
</p>
<br />
<h3>Accounts</h3>
<p>You are responsible for every activity that occur under your account either by you or users you grant access to. It is therefore your responsibility to ensure your credentials are secure and best security practices are ensured.
<br /><br />
You may not use the Service for any illegal or unauthorised purpose. You must not, in the use of the Service, violate any laws in your jurisdiction (including but not limited to copyright or trademark laws).
<br /><br />
You can delete your account anytime by accessing your profile page in the Emailkick dashboard. This will remove all associated data linked to your account.
</p>
<h3>Payment and Refunds</h3>
<p>You have 7 days free trial or first 5k events to test the service and be sure you want to pay. Once you are convinced and add your payment details, you will be charged on the last day of every month based on the number of events consumed for the month as detailed in pricing. You will be sent an invoice some hours earlier to remind you and let you know the expected charge.
<br /><br />
You can cancel your subscription anytime. Once canceled, you will not be charged for that month and henceforth, but your domains will also stop receiving events from Mailgun. You can always resubscribe to get your domains enabled again. If you resubscribe outside the month you cancelled, you will be charged for events used in the month you cancelled. What this means is if for example you cancelled on May 20 (having used 5k events) and resubscribed July 3, you will immediately be charged for the pending 5k events for May. (July’s charge comes on the last day of July).
<br /><br />
Once cancelled, events for your domains will still be available for 30 days. This is so in case you change your mind. After that, they will be completely removed from the service. Your profile will still be available though. You can completely remove your account from your settings page on Emailkick.
<br /><br />
Payments on Emailkick is powered by Stripe, a leader in online payments. No card details is communicated with or stored on the Emailkick server. Stripe takes care of this securely.
<br /><br />
Note that your payment description will read "Every Good". This is the parent company for Emailkick.
<br /><br />
If for any reason you think you deserve a refund for any of your payments, kindly send a mail to hello@Emailkick.io. It will be promptly addressed.
</p><br />
<h3>Modifications to Service and Prices</h3>
<p>Emailkick reserves the right to modify or discontinue, temporarily or permanently, the Service (or any part thereof) with prior notice.
<br /><br />
Prices are subject to change upon 30 days of notice. Such notice may be provided at any time by posting the changes to the blog or twitter account (@Emailkick).
<br />
Emailkick shall not be liable to you or to any third-party for any modification, price change, suspension or discontinuation of the Service.
</p><br />
<h3>Contact</h3>
<p>Questions about the Terms of Service should be sent to @Emailkick or hello@Emailkick.io</p>


    </div>




  </Layout>
)

export default IndexPage
